<template>
    <div class="main">
         <TheTopbar/>
      <slot />
  
      <div> 
          <div class="options-bar">
                      <div class="options">
                          <button class="btn btn-main" :class="{ active: state == 'main'}" @click="changeState('main')" >OVERVIEW</button>
                          <button class="btn btn-main" :class="{ active: state == 'compliance'}" @click="changeState('compliance')" >COMPLIANCE</button>
                      </div>
                  </div>
      </div>
      <div v-if="state == 'compliance'"> 
          <div class="" >
                  
                  <div class="lowerGraphBox">
                      <div class="lowerBox">
                        
                          <div v-if="isLoading" class="loading-spinner"></div>
                          <label style="width: 300px;">Company Risk Level in Fintech</label>
                        
                         <HalfPie/>
      
                      </div>
                      <div class="lowerBox">
                          <div v-if="isLoading" class="loading-spinner"></div>
                          <label style="width: 300px;">Company Risk Level in Gaming</label>
                          
                          <HalfPieG/>
                      </div>
                   
                      <div class="lowerBox"  >
                          <div v-if="isLoading" class="loading-spinner"></div>
                          <label style="width: 300px;">Compliance Met </label>
                          <div style="font-size: 50px">{{ totalCompliant }} / {{totalOperators}} </div>
                      </div>
                      
                  </div>
              </div>
              <div> 
                  <div > 
                   
                      <div class="market-content">
                          <label>Risk Level </label>
                          <table class="table"> 
                              <thead>
                                  <th>Host</th>
                                  <th>Entity Id</th>
                                  <th>Business Nature</th>
                                  <th>Risk Level (Overall Rating)</th>
                              </thead>
                              <tbody v-for="data in merged" :key="data.id" v-if="data.riskLevel != null">
                                  <td>{{ data.signupHost }}</td>
                                   <td> <a :href="'/#/customer-profile/' + data.entityId">{{ data.entityId }}</a></td>
                                  <td></td>
                                  <td v-if="data.riskLevel == 'LOW'" class="btn btn-light">{{data.riskLevel}}</td>
                                  <td v-if="data.riskLevel == 'MEDIUM'" class="btn btn-warning">{{data.riskLevel}}</td>
                                  <td v-if="data.riskLevel == 'HIGH'" class="btn btn-danger">{{data.riskLevel}}</td>
  
                              </tbody>
                          </table>
                      </div>
                  </div>
                 </div>
      </div>
     
    </div>
      
          
      
     
  </template>
  <script>
  import TheTopbar from '../components/TheTopbar.vue'
  import moment from 'moment'
  import HalfPie from '../view/Chart/HalfPie.vue'
  import HalfPieG from '../view/Chart/HalfPieG.vue'
  
  
  
  
  export default {
      name: 'Dashboard',
      components: {
          TheTopbar,
          HalfPie,
          HalfPieG
  
            
      },
      data:() => {
          return{
              state:'compliance',
              pending: [],
              customersFintech:[],
              customersGaming:[],
              fintech:[],
              gaming:[],
              numberOfDays: '31',
              signupHost:'',
              noOfFintech:'',
              labelG: [],
              dataValueG: [],
              labelF: [],
              dataValueF: [],
              isLoading: false,
              details:'',
              showProvExpiry: false,
              showFullExpiry: false,
              sortedData: [],
              low:'',
              high:'',
              medium:'',
              noOfFintech: '',
              noOfGaming:''
             
            }
      },
        
  
      computed:{
         
          init() {
            return this.$store.state.init;
          },
          show() {
            return this.$store.state.show;
          },
          totalOperators(){
              return this.customersFintech.length + this.customersGaming.length;
          },
          sortedProvisional(){
              const mergedProvisional = [...this.fintech, ...this.gaming];
              return mergedProvisional.sort((a,b) => new Date(a.provisionalLicenseExpirationDate) - new Date(b.provisionalLicenseExpirationDate))
              
          },
          sortedFull(){
              const mergedFull = [...this.fintech, ...this.gaming];
              return mergedFull.sort((a,b) => new Date(a.fullLicenseExpirationDate) - new Date(b.fullLicenseExpirationDate))
          },
          merged(){
              const mergedData = [...this.fintech, ...this.gaming];
              return mergedData.sort((a,b) => new Date(a.fullLicenseExpirationDate) - new Date(b.fullLicenseExpirationDate))
          },
          totalCompliant(){
            return this.noOfFintech + this.noOfGaming
          }
         
        
      },
    
      
      methods:{
          formatDate: function(date) {
            return moment(new Date(date)).format('MM/DD/YYYY');
          },
          
          viewLogs(){
  
          },
          openProvisionalExpiry(){
              this.getAllDetailsFintech();
              this.getAllDetailsGaming();
              this.showProvExpiry = true;
              this.showFullExpiry = false
          },
          openFullExpiry(){
              this.getAllDetailsGaming();
              this.getAllDetailsFintech();
              this.showFullExpiry = true;
              this.showProvExpiry = false;
          },
          getAllDetailsFintech(){
                  const params = {
                    signupHost: 'fintech.ceza.org',
                    limit: '50000',
                    offset: '0',
                  }
  
                  const options = {
                      method: 'POST',
                      headers: { 'content-type': 'application/x-www-form-urlencoded',
                          'vmiadmin-authz': localStorage.getItem("tokenId")  },
                      url: '/ax/customersearch/csv',
                      params
                      };
  
                  this.axios(options)
                  .then((response) => { 
                   this.fintech = response.data
                  

                   let statusArr = [];

                   this.fintech.forEach((el, i) => {
                
                    if(el.riskLevel != null){
                        this.noOfFintech = el.riskLevel.length
                      statusArr.push(el.riskLevel)
                      
                     
                    }

                    let countMap = statusArr.reduce((el, i) => {
                        el[i] = (el[i] || 0) + 1;
                        return el;
                    }, {});
  
                    let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));
  
  
                    for(let i = 0;i < countArray.length ; i++) {
  
                        this.labelF.push(countArray[i].value);
                        this.dataValueF.push(countArray[i].count);
                     
                    }
                  })
                    
                   
                  })
              },
          getAllDetailsGaming(){
                  const params = {
                    signupHost: 'gaming.ceza.org',
                    limit: '50000',
                    offset: '0',
                  }
  
                  const options = {
                      method: 'POST',
                      headers: { 'content-type': 'application/x-www-form-urlencoded',
                          'vmiadmin-authz': localStorage.getItem("tokenId")  },
                      url: '/ax/customersearch/csv',
                      params
                      };
  
                  this.axios(options)
                  .then((response) => { 
                   this.gaming = response.data
                   let statusArr = [];

                    this.gaming.forEach((el, i) => {

                    if(el.riskLevel != null){
                        this.noOfGaming = el.riskLevel.length
                    statusArr.push(el.riskLevel)
                    
                    
                    }

                    let countMap = statusArr.reduce((el, i) => {
                        el[i] = (el[i] || 0) + 1;
                        return el;
                    }, {});

                    let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));


                    for(let i = 0;i < countArray.length ; i++) {

                        this.labelF.push(countArray[i].value);
                        this.dataValueF.push(countArray[i].count);
                    
                    }
                    })
                 
                   
                  })
              },
          changeState(state) {
              this.state = state;
            if(this.state == 'main'){
              window.location.href="/#/"
            }
             
            },
          getDetails(){
              this.isLoading = true
                  const options = {
                      method: 'GET',
                      headers: { 'content-type': 'application/x-www-form-urlencoded',
                          'vmiadmin-authz': localStorage.getItem("tokenId")  },
                      url: '/ax/businessfile/details',
                      
                      };
  
                this.axios(options)
                .then((response) => { 
                  this.isLoading = false
                  console.log(response.data)
                  this.details = response.data
                  });
      },
          searchByGaming(){
              this.isLoading = true
               const params = {
                    signupHost: 'gaming.ceza.org',
                    itemsPerPage: '100',
                    limit: '50000',
                    offset: '0',
                  }
  
                  const options = {
                      method: 'POST',
                      headers: { 'content-type': 'application/x-www-form-urlencoded',
                          'vmiadmin-authz': localStorage.getItem("tokenId")  },
                      url: '/ax/customerSearchBySignupHost/',
                      params
                      };
  
                this.axios(options)
                .then((response) => { 
                  this.isLoading = false
                  this.customersGaming = response.data
                  
                    let statusArr = [];
  
                    response.data.forEach((el, i) => {
                        statusArr.push(el.verificationStatus);
                    })
                    
  
                    let countMap = statusArr.reduce((el, i) => {
                        el[i] = (el[i] || 0) + 1;
                        return el;
                    }, {});
  
                    let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));
  
  
                    for(let i = 0;i < countArray.length ; i++) {
  
                        this.labelG.push(countArray[i].value);
                        this.dataValueG.push(countArray[i].count);
                     
                    }
  
       
                  });
      },
          searchByFintech(){
              this.isLoading = true
               const params = {
                    signupHost: 'fintech.ceza.org',
                    itemsPerPage: '100',
                    limit: '50000',
                    offset: '0',
                  }
  
                  const options = {
                      method: 'POST',
                      headers: { 'content-type': 'application/x-www-form-urlencoded',
                          'vmiadmin-authz': localStorage.getItem("tokenId")  },
                      url: '/ax/customerSearchBySignupHost/',
                      params
                      };
  
                this.axios(options)
                .then((response) => { 
                  this.isLoading = false
                  this.customersFintech = response.data
                    let statusArr = [];
  
                    response.data.forEach((el, i) => {
                        statusArr.push(el.verificationStatus);
                    })
                    
  
                    let countMap = statusArr.reduce((el, i) => {
                        el[i] = (el[i] || 0) + 1;
                        return el;
                    }, {});
  
                    let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));
  
  
                    for(let i = 0;i < countArray.length ; i++) {
  
                        this.labelF.push(countArray[i].value);
                        this.dataValueF.push(countArray[i].count);
                     
                    }
  
       
                  });
      },
        
          
              checkSession: function() {
  
                  const options = {
                  method: 'POST',
                  headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                  url: '/ax/sessionCheck',
                  };
  
                  this.axios(options)
                  .then((response) => { 
                      console.log(response.data)
                    
                      }).catch((err) => {
                          
                      if(err.response.data.msgCode == '00001') {
                          window.location.href = "/#/login";
                      }  
                      })
              },
            
      },
      mounted(){
          this.checkSession();
          // this.getPendingSignup();
          this.searchByFintech();
          this.searchByGaming();
          this.getDetails();
         this.getAllDetailsFintech();
         this.getAllDetailsGaming();
      }
     
       
  
  
          
  }
  </script>
  
  <style lang="scss" scoped>
  .upperGraphBox{
      position: relative;
      width: 100%;
      height: auto;
      padding: 20px;
      display: grid;
      grid-template-columns: 3fr;
      grid-gap: 10px;
      min-height: 300px;
      min-width: 200px;
  
  }
  .upperGraphBox .upperBox{
      position: relative;
      background: white;
      padding: 20px;
      width: 100%;
      box-shadow: 0 7px 25px rgba(0,0,0,08);
      border-radius: 20px;
  }
  .lowerGraphBox{
      position: relative;
      width: 100%;
      height: 100%;
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
      min-height: 200px;
      min-width: 200px;
  
  }
  .lowerGraphBox .lowerBox{
      position: relative;
      background: white;
      padding: 20px;
      width: 100%;
      box-shadow: 0 7px 25px rgba(0,0,0,08);
      border-radius: 20px;
  }
  .graphBox{
      position: relative;
      width: 100%;
      padding: 20px;
      display: grid;
      grid-template-columns: 2fr 2fr;
      grid-gap: 30px;
      min-height: 200px;
  
  }
  .graphBox .box{
      position: relative;
      background: white;
      padding: 20px;
      width: 100%;
      box-shadow: 0 7px 25px rgba(0,0,0,08);
      border-radius: 20px;
  }
  .small-box{
      position: relative;
      background: white;
      padding: 50px;
      width: 40%;
      height: 180px;
      box-shadow: 0 7px 25px rgba(0,0,0,08);
      border-radius: 20px;
      margin: auto 20px !important;
  }
  
  .loading-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #007BFF; /* Change the color to your preferred color */
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
        margin: 0 auto;
      }
      @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  th, td {
    padding: 0.5em;
    z-index: 9999 !important;
    color: black;
  }
  tr{
    width: 100%;
    padding: 0.5em 2em;
  }
  .table thead{
    background: #92929250 !important;
    width: 100% !important;
    color: black;
    font-size: 0.75rem;
    letter-spacing: 2%;
    border-radius: 10px;
    z-index: 9999 !important;
  }
  
  
  </style>
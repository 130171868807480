<template>
	<div class="main">
    
	  <div class="main-container" v-if="option == 'individual'">
                   <div>
                      <h6>Transaction Monitoring (BEH-Scoring)</h6>
                          <div class="market-content"> 
                            <table class="table" >
                              <thead>
                                <tr>
                                    <th>Criteria</th>
                                    <th>Risk Level</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Number of Customers</td>
                                  <td>Very small - small - medium - large</td>
                                </tr>
                                <tr>
                                  <td>Turnover(PHP) Daily</td>
                                  <td>Very low - low - medium - high</td>
                                </tr>
                                <tr>
                                  <td>Customer Risk Jurisdiction</td>
                                  <td>low - medium - high</td>
                                </tr>
                                <tr>
                                  <td>Number of Players</td>
                                  <td>1 - 1000 and above</td>
                                </tr>
                                </tbody>
                                </table>
                          </div>
                        </div>
                              
      </div>
      

    
    </div>
</template>
<script>
  export default {
      name: 'Idv',
      data: function() {
        return { 
            option: 'individual',
            tab:'individualScore',
        }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
      },
      methods:{
      }
  }
</script>
<style scoped lang="scss">
.market-content {
  width: 100%;
      border-radius: 10px;
      padding: 0;
      max-height: 300px; /* Set your desired maximum height */
      overflow: auto;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
</style>
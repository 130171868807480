<template>
  <div class="row" >
    <div class="column" > 
      <canvas id="halfPie" width="400" height="400"></canvas>
    </div>
  
  </div>
  
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  props: [
  
  ],
  data() {
    return {
      noOfFintech:'',
      label: [],
      dataValue: [],
      customersFintech:[],
      labelObj:[],
      compliant: ''
    };
  },
  computed: {
    labelData() {
      // Manipulate the label data here if needed
      return this.label.slice(0); // Assuming you want only the first three labels
    }
  },
  mounted() {
    this.searchByFintech();
  

    
  },
  methods: {
    searchByFintech(){
             const params = {
		          signupHost: 'fintech.ceza.org',
                  itemsPerPage: '100',
                  limit: '50000',
                  offset: '0',
		        }

                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/customersearch/csv',
                    params
                    };

              this.axios(options)
              .then((response) => { 
                this.customersFintech = response.data
                this.customersFintech.sort(function(a, b) {
                        return parseFloat(b.riskLevel) - parseFloat(a.riskLevel);
                    });

                  let statusArr = [];

                  this.customersFintech.forEach((el, i) => {
                    if(el.riskLevel != null){
                      this.compliant = el.riskLevel.length
                      statusArr.push(el.riskLevel);
                    }
                  })

                  statusArr.sort();
                  let countMap = statusArr.reduce((el, i) => {
                      el[i] = (el[i] || 0) + 1;
                      return el;
                  }, {});

                  let countArray = Object.keys(countMap).map(key => ({ value: key, count: countMap[key] }));


                  for(let i = 0;i < countArray.length ; i++) {

                      this.label.push(countArray[i].value);
                      this.dataValue.push(countArray[i].count);

                  
                  }
                 
                  const data = {
                  labels:
                     this.label,
                    datasets: [{
                      label: 'My First Dataset',
                      data: this.dataValue,
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)'
                      ],
                      hoverOffset: 4
                    }]
                    };
                    const config = {
                      type: 'doughnut',
                      data,
                      options: {
                      rotation: -90,
                      circumference: 180,
                    }
                    };

                    const halfPie =  new Chart(
                      document.getElementById("halfPie"),
                      config
                    )      
                });
    },
  
  }
}
</script>

<style lang="scss" scoped>

</style>
